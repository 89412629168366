<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="6">
        <GroupFile @on-select="handleSelect" />
      </v-col>
      <v-col cols="6">
        <KodeImage ref="kodeImage" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
const GroupFile = () => import("./groupFile");
const KodeImage = () => import("./kodeImage");

export default {
  components: {
    GroupFile,
    KodeImage
  },
  methods: {
    handleSelect(item) {
      this.$refs.kodeImage.onSelect(item);
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
